<template>
  <div class="group">
    <h3>Group page</h3>
  </div>
</template>

<script>
  //import somethingModule from '@/store/something'
  import feedbackModule from '@/store/feedback'
  import experienceModule from '@/store/experience'

  export default {
    name: "group",
    components: {

    },
    data () {
      return {
        
        
      }
    },
    methods: {
      
    },
    beforeCreate () {
      //if(!this.$store.state.something) this.$store.registerModule('something', somethingModule)
      if(!this.$store.state.feedback) this.$store.registerModule('feedback', feedbackModule)
      if(!this.$store.state.experience) this.$store.registerModule('experience', experienceModule)
    },
    created () {
      //gtag('config', 'G-H9RD8W4MLR', {'page_path': '/group'});
    },
    mounted () {

    },
    updated () {

    },
    watch: {

    }
  }
</script>

<style scoped>

</style>
